.content {
    width: 700px;
    display: flex;
    flex-direction: column;
    -ms-flex-item-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
}

.login-content {
    position: absolute;
    z-index: 10000; 
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
}