.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: cursive;
}

.site-layout .site-layout-background {
  background: #fff;
}